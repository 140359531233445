import { useState } from "react";
import "./App.scss";
import Cursor from "./components/Cursor/Cursor";
import Homepage from "./page/Homepage";

function App() {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div className="App" id={theme}>
      {window.innerWidth >= 768 ? <Cursor /> : ""}
      <Homepage toggleTheme={toggleTheme} theme={theme} />
    </div>
  );
}

export default App;
