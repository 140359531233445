import "./Contact.scss";

import React from "react";

const Contact = () => {
  return (
    <section className="contact">
      <p className="contact__title-container">
        <span className="contact__title-text">LET'S </span>
        <span className="contact__title-text contact__title-text--bottom">
          CONNECT
        </span>
      </p>
      <div className="contact__cta">
        <div className="contact__interests">
          <p className="contact__interests-intro">
            If you're looking for a developer who cares about
          </p>
          <a
            href="mailto:henryluan95@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <span className="contact__interest contact__interest--right">
              full-stack development
            </span>
          </a>
          <a
            href="mailto:henryluan95@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <span className="contact__interest contact__interest--left">
              UX/UI design
            </span>
          </a>
          <a
            href="mailto:henryluan95@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <span className="contact__interest">Web Accessibility</span>
          </a>
          <a
            href="mailto:henryluan95@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <span className="contact__interest contact__interest--right">
              Communication
            </span>
          </a>
          <a
            href="mailto:henryluan95@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <span className="contact__interest contact__interest--left">
              Team Connection
            </span>
          </a>
          <a
            href="mailto:henryluan95@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <span className="contact__interest">Free Food</span>
          </a>
        </div>
        <div className="contact__socials">
          <a
            href="https://github.com/henryluan95"
            className="contact__social contact__social--left"
            target="_blank"
            rel="noreferrer"
          >
            Github
          </a>
          <a
            href="https://www.linkedin.com/in/henry-luan/"
            className="contact__social"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
