import "./About.scss";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const aboutRef = useRef(null);
  const henryLuanRef = useRef(null);

  const nameAnimation = () => {
    const about = aboutRef.current;
    const name = henryLuanRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: about,
        start: "top 70%",
        end: "top 70%",
      },
    });

    tl.from(name.firstChild, {
      xPercent: 100,
      duration: 2,
    }).from(
      ".about__text > *",
      {
        xPercent: -100,
        duration: 2,
      },
      "<"
    );
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      nameAnimation();
    }
  }, []);

  return (
    <section className="about" ref={aboutRef}>
      <h2 className="about__author-name" ref={henryLuanRef}>
        <span>Henry Luan</span>
      </h2>
      <div className="about__info">
        <p className="about__text">
          <span>
            I’m a Full Stack Web Developer with some footprints in the finance
            world.
          </span>
        </p>
        <p className="about__text">
          <span>
            The thing that makes me happy the most in life is being able to help
            people. I value my curiosity and empathy toward others. Whenever I
            work on a project, I keep these core beliefs close to my heart.
          </span>
        </p>
      </div>
    </section>
  );
};

export default About;
