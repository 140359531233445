import "./Cursor.scss";
import { useEffect, useRef } from "react";

const Cursor = () => {
  //get the cursor
  const cursor = useRef(null);

  //handle mouse move
  const handleMouseMove = (e) => {
    cursor.current.style.top = e.clientY + "px";
    cursor.current.style.left = e.clientX + "px";
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
  });

  return <div className="cursor" ref={cursor}></div>;
};

export default Cursor;
