import "./ToggleButton.scss";

const ToggleButton = ({ toggleTheme, theme, modifier }) => {
  return (
    <div
      className={`toggle toggle--${modifier}`}
      onClick={() => {
        toggleTheme();
      }}
    >
      <div
        className={`toggle__button  ${
          theme === "light" ? "toggle__button--active" : ""
        }`}
      ></div>
    </div>
  );
};

export default ToggleButton;
