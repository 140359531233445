import "./Header.scss";
import resume from "../../assets/henry-luan-resume.pdf";

const Header = () => {
  return (
    <header className="header">
      <a href="mailto:henryluan95@gmail.com" className="header__contact">
        Contact Me
      </a>
      <p className="header__name">Henry Luan</p>
      <a href={resume} className="header__resume" download="henry-luan-resume">
        Resume
      </a>
    </header>
  );
};

export default Header;
