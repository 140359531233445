import React from "react";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import Projects from "../components/Projects/Projects";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Skills from "../components/Skills/Skills";

const Homepage = ({ toggleTheme, theme }) => {
  return (
    <>
      <Header />
      <main>
        <Hero toggleTheme={toggleTheme} theme={theme} />
        <Projects />
        {/* <Skills /> */}
        <About />
      </main>
      <footer>
        <Contact />
      </footer>
    </>
  );
};

export default Homepage;
