import "./Projects.scss";
import paperman from "../../assets/images/paperman.png";
import thirdproject from "../../assets/images/brainflix.png";
import myhappyplace from "../../assets/images/myhappyplace.png";
import whatthecase from "../../assets/images/whatthecase.png";
import moodygif2 from "../../assets/images/moodygif2.png";
import bmo from "../../assets/images/bmo.png";
import gsap, { Power4 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
  //Create refs
  const projectTitleRef = useRef(null);
  const projectDescRef = useRef(null);
  const firstProjectBannerRef = useRef(null);
  const firstProjectStackRef = useRef(null);
  const secondProjectBannerRef = useRef(null);
  const secondProjectStackRef = useRef(null);
  const thirdProjectBannerRef = useRef(null);
  const thirdProjectStackRef = useRef(null);
  const whatTheCaseRef = useRef(null);
  const bmoRef = useRef(null);
  const gifRef = useRef(null);
  const otherProjectsTitleRef = useRef(null);
  const bmoTextRef = useRef(null);
  const whatTheCaseTextRef = useRef(null);

  //Animations
  //Create a function to handle Intro Animation
  const projectIntroAnimation = () => {
    //Get elements
    const projectTitle = projectTitleRef.current;
    const projectDesc = projectDescRef.current;

    //Initiate timeline
    const tl = gsap.timeline({ ease: Power4.easeOut });
    tl.from(projectTitle, {
      xPercent: 200,
      duration: 1,
      scrollTrigger: {
        trigger: ".projects__intro",
        start: "top center",
        end: "top 40%",
        scrub: 4,
      },
    })
      .from(
        projectDesc,
        {
          xPercent: -220,
          scrollTrigger: {
            trigger: ".projects__intro",
            start: "top center",
            end: "top 40%",
            scrub: 4,
          },
        },
        "<"
      )
      .to([projectTitle, projectDesc], {
        y: -500,
        scale: 0.8,
        skewX: 15,
        transformOrigin: "center center",
        scrollTrigger: {
          trigger: ".projects__first",
          start: "top 70%",
          end: "top top",
          scrub: 4,
        },
      });
  };

  //Create functions to handle Banners Animation
  const firstProjectBannerAnimation = () => {
    //get element
    const firstProjectBanner = firstProjectBannerRef.current;
    const firstProjectStack = firstProjectStackRef.current;

    //Set up timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".projects__first",
        scrub: 4,
        start: "top bottom",
        end: "bottom top",
      },
      ease: Power4.easeOut,
    });

    tl.fromTo(
      firstProjectBanner,
      { xPercent: -25 },
      {
        xPercent: -60,
      }
    ).from(firstProjectStack, {
      opacity: 0,
      y: 25,
      scrollTrigger: {
        trigger: firstProjectStack,
        scrub: 4,
        start: "top 70%",
        end: "top 65%",
      },
    });
  };

  const secondProjectBannerAnimation = () => {
    //get element
    const secondProjectBanner = secondProjectBannerRef.current;
    const secondProjectStack = secondProjectStackRef.current;

    //Set up timeline
    const tl = gsap.timeline({ ease: Power4.easeOut });
    tl.fromTo(
      secondProjectBanner,
      { xPercent: -25 },
      {
        xPercent: -60,
        scrollTrigger: {
          trigger: ".projects__second",
          scrub: 4,
          start: "top bottom",
          end: "bottom top",
        },
      }
    ).from(secondProjectStack, {
      opacity: 0,
      y: 25,
      scrollTrigger: {
        trigger: secondProjectStack,
        scrub: 4,
        start: "top 70%",
        end: "top 65%",
      },
    });
  };

  const thirdProjectBannerAnimation = () => {
    //get element
    const thirdProjectBanner = thirdProjectBannerRef.current;
    const thirdProjectStack = thirdProjectStackRef.current;

    //Set up timeline
    const tl = gsap.timeline({ ease: Power4.easeOut });
    tl.fromTo(
      thirdProjectBanner,
      { xPercent: -30 },
      {
        xPercent: -60,
        scrollTrigger: {
          trigger: ".projects__third",
          scrub: 4,
          start: "top bottom",
        },
      }
    ).from(thirdProjectStack, {
      opacity: 0,
      y: 25,
      scrollTrigger: {
        trigger: thirdProjectStack,
        scrub: 4,
        start: "top 70%",
        end: "top 65%",
      },
    });
  };

  const otherProjectsAnimation = () => {
    //get element
    const otherProjectsTitle = otherProjectsTitleRef.current;

    //initiate timeline
    const tl = gsap.timeline({ ease: Power4.easeOut });
    tl
      //Animate full stack text
      .from(otherProjectsTitle.firstChild, {
        y: 200,
        scrollTrigger: {
          trigger: otherProjectsTitle,
          start: "top 80%",
          end: "top 70%",
          scrub: 4,
        },
      })
      .from(".others__title", {
        x: -100,
        stagger: 0.2,
        scrollTrigger: {
          trigger: otherProjectsTitle,
          start: "top 80%",
          end: "top 70%",
          scrub: 2,
        },
      });
  };

  //Create a function to create new other project animation
  const createOtherProject = (projectRef) => {
    //Create a function to animate the project
    const projectAnimation = () => {
      //Initiate animation
      projectRef.current = gsap.to(projectRef.current, {
        autoAlpha: 1,
        duration: 1,
        paused: true,
      });
    };

    //Create a function to handle mouse enter the project
    const projectMouseEnter = () => {
      projectRef.current.play();

      //Animate circle out
      gsap.to(".circle__top", {
        y: 100,
        duration: 1,
        ease: Power4.easeOut,
      });
    };

    //Create a function to handle mouse leave the project
    const projectMouseLeave = () => {
      projectRef.current.reverse();

      //Animate circle back in
      gsap.to(".circle__top", {
        y: 135.04,
        duration: 1,
        ease: Power4.easeOut,
      });
    };

    return { projectAnimation, projectMouseEnter, projectMouseLeave };
  };

  //Use createOtherProject function to create whatTheCaseAnimation
  const {
    projectAnimation: whatTheCaseAnimation,
    projectMouseEnter: handleWhatTheCaseMouseEnter,
    projectMouseLeave: handleWhatTheCaseMouseLeave,
  } = createOtherProject(whatTheCaseRef);

  //Use createOtherProject function to create whatTheCaseAnimation
  const {
    projectAnimation: bmoAnimation,
    projectMouseEnter: handleBmoMouseEnter,
    projectMouseLeave: handleBmoMouseLeave,
  } = createOtherProject(bmoRef);

  //Use createOtherProject function to create whatTheCaseAnimation
  const {
    projectAnimation: gifAnimation,
    projectMouseEnter: handleGifMouseEnter,
    projectMouseLeave: handleGifMouseLeave,
  } = createOtherProject(gifRef);

  //Create animation for circles
  const circlesAnimation = () => {
    const tl = gsap.timeline();
    tl.to(".circle", {
      x: "random(-20, 20, 5)",
      y: "random(-20, 10, 3)",
      duration: 3,
      ease: "none",
      repeat: -1,
      repeatRefresh: true,
    })
      .to(
        ".circle__first",
        {
          rotation: "random(-30, 30 , 5)",
          duration: 3,
          ease: "none",
          repeat: -1,
          repeatRefresh: true,
        },
        "<"
      )
      .to(
        ".circle__second",
        {
          rotation: "random(-70, 70 , 5)",
          duration: 3,
          ease: "none",
          repeat: -1,
          repeatRefresh: true,
        },
        "<"
      )
      .to(
        ".circle__first",
        {
          rotation: "random(-50, 50 , 5)",
          duration: 3,
          ease: "none",
          repeat: -1,
          repeatRefresh: true,
        },
        "<"
      )
      .to(
        ".circle__third",
        {
          rotation: "random(-100, 100 , 5)",
          duration: 5,
          ease: "none",
          repeat: -1,
          repeatRefresh: true,
        },
        "<"
      );
  };

  //Create animation for single circle
  const handleMouseEnterBall = (e) => {
    console.log(e.currentTarget.firstChild);
    //Animate circle out
    gsap.to(e.currentTarget.firstChild, {
      y: 100,
      duration: 1,
      ease: Power4.easeOut,
    });
  };

  const handleMouseLeaveBall = (e) => {
    //Animate circle back in
    gsap.to(e.currentTarget.firstChild, {
      y: 135.04,
      duration: 1,
      ease: Power4.easeOut,
    });
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      projectIntroAnimation();
      firstProjectBannerAnimation();
      secondProjectBannerAnimation();
      thirdProjectBannerAnimation();
      whatTheCaseAnimation();
      bmoAnimation();
      gifAnimation();
      otherProjectsAnimation();
      circlesAnimation();

      const refreshAfterLoad = setTimeout(() => {
        ScrollTrigger.refresh();
      }, 2000);

      return () => {
        clearTimeout(refreshAfterLoad);
      };
    }

    return;
  }, []);

  return (
    <section className="projects">
      <div className="projects__intro">
        <h2 className="projects__title" ref={projectTitleRef}>
          PROJECTS
        </h2>
        <p className="projects__description" ref={projectDescRef}>
          This is a showcase for my most current projects. Most of the projects
          are my side projects while others are collaborations between multiple
          developers/teams.
        </p>
      </div>
      {/* Paperman Section */}
      <section className="projects__first project">
        <h3 className="project__title">Paperman</h3>
        <p className="project__description">
          Paperman is an ecommerce shop for iPhone cases focusing on high
          quality and fashionable cases!
        </p>
        <p className="project__description">
          This project is a redesigned website for Paperman. This version
          focuses on improving the UX/UI aspect of the website by adding web
          accessibility, call to actions and implementing cleaner design, better
          user interactions.
        </p>
        <p className="project__description" ref={firstProjectStackRef}>
          Tech stack: React, SASS, Firebase (MySQL + Knex), Lightroom, and
          Figma.
        </p>
        <div className="project__image-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://paperman.vercel.app/"
            className="project__link"
          >
            <img
              className="project__image project__image--large"
              src={paperman}
              alt="Paperman screenshot"
            />
          </a>
          <p
            className="project__image-text project__image-text--align "
            ref={firstProjectBannerRef}
          >
            PAPERMAN PAPERMAN PAPERMAN PAPERMAN PAPERMAN PAPERMAN PAPERMAN
            PAPERMAN PAPERMAN PAPERMAN PAPERMAN PAPERMAN PAPERMAN PAPERMAN
          </p>
        </div>
      </section>
      {/* My Happy Place Section */}
      <section className="projects__second project">
        <h3 className="project__title">My Happy Place</h3>
        <p className="project__description ">
          My happy place on earth is a project that I built while learning
          Socket.io. This project is inspired by The Nicest Place On The
          Internet. It features videos of strangers hugging their cameras with
          soft music playing in the background. It also includes a chatbox for
          strangers to send encouraging messages to others.
        </p>
        <p className="project__description " ref={secondProjectStackRef}>
          Tech stack: React, SASS, Express, Node.js, and Socket.io.
        </p>
        <div className="project__image-container project__image-container--front-screen">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/henryluan95/my-happy-place"
            className="project__link"
          >
            <img
              className="project__image project__image--large"
              src={myhappyplace}
              alt="My Happy Place screenshot"
            />
          </a>
          <p className="project__image-text" ref={secondProjectBannerRef}>
            MYHAPPYPLACE MYHAPPYPLACE MYHAPPYPLACE MYHAPPYPLACE MYHAPPYPLACE
            MYHAPPYPLACE MYHAPPYPLACE MYHAPPYPLACE MYHAPPYPLACE MYHAPPYPLACE
          </p>
        </div>
      </section>
      {/* MoodyGif Section */}
      <section className="projects__third project">
        <h3 className="project__title">BrainFlix</h3>
        <p className="project__description ">
          BrainFlix is a responsive full-stack web application that mimics
          YouTube. The home page features a large full-width video player,
          information about the video including the channel's name, view and
          like counts, and a description. It also includes a comment section
          where users can post and delete comments on a video. Users can also
          browse a section of recommended videos on the sidebar. The upload page
          lets user upload new video to the website.
        </p>
        <p className="project__description ">
          I utilized the React framework, libraries such as React Router and
          Axios to develop the site’s front-end, as well as Node and Express to
          create a back-end API.
        </p>
        <p className="project__description" ref={thirdProjectStackRef}>
          Tech stack: HTML, SASS, Javascript, NodeJS, and Express.
        </p>
        <div className="project__image-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://henry-luan-brainfix.vercel.app/"
            className="project__link"
          >
            <img
              className="project__image"
              src={thirdproject}
              alt="moody gif screenshot"
            />
          </a>
          <p className="project__image-text" ref={thirdProjectBannerRef}>
            BRAINFLIX BRAINFLIX BRAINFLIX BRAINFLIX BRAINFLIX BRAINFLIX
            BRAINFLIX
          </p>
        </div>
      </section>
      {/* Other Projects Section */}
      <section className="projects__forth project">
        <h3 className="project__title" ref={otherProjectsTitleRef}>
          <span>Others</span>
        </h3>

        <div className="project__others others">
          <div className="others__container">
            <a
              className="others__title others__title--top"
              onMouseEnter={handleGifMouseEnter}
              onMouseLeave={handleGifMouseLeave}
              href="https://what-the-case.vercel.app/"
              target="_blank"
              rel="noreferrer"
              ref={gifRef}
            >
              VIEW GIF PROJECT
            </a>
            <a
              className="others__title others__title"
              onMouseEnter={handleWhatTheCaseMouseEnter}
              onMouseLeave={handleWhatTheCaseMouseLeave}
              href="https://moody-gif.vercel.app/"
              target="_blank"
              rel="noreferrer"
              ref={whatTheCaseTextRef}
            >
              VIEW WHATTHECASE PROJECT
            </a>
            <a
              className="others__title others__title"
              onMouseEnter={handleBmoMouseEnter}
              onMouseLeave={handleBmoMouseLeave}
              href="https://bmo-hackathon.vercel.app/"
              target="_blank"
              rel="noreferrer"
              ref={bmoTextRef}
            >
              VIEW BMO PROJECT
            </a>
            <a
              className="others__title others__title"
              href="https://github.com/henryluan95"
              target="_blank"
              rel="noreferrer"
            >
              VIEW MORE - GITHUB
            </a>
          </div>
          <div className="others__image-container">
            {/* circle 1 */}
            <div
              className="circle circle__first"
              onMouseEnter={handleMouseEnterBall}
              onMouseLeave={handleMouseLeaveBall}
            >
              <div className="circle__top">
                <svg viewBox="0 0 609.1 608.9" fill="#ffaf6f">
                  <ellipse
                    cx="304.5"
                    cy="304.4"
                    rx="304.5"
                    ry="70.5"
                    fill="#ffaf6f"
                  ></ellipse>
                  <path d="M0,304.5C0,136.3,136.3,0,304.5,0S609,136.3,609,304.5"></path>
                </svg>
              </div>
              <div className="circle__bottom">
                <svg viewBox="0 0 609.1 608.9" fill="#ffaf6f">
                  <path d="M609.1,304.4c0,168.2-136.3,304.5-304.5,304.5S0,472.6,0,304.4"></path>
                  <ellipse
                    cx="304.5"
                    cy="304.4"
                    rx="304.5"
                    ry="70.5"
                    fill="#ff933b"
                  ></ellipse>
                </svg>
              </div>
            </div>
            {/* circle 2 */}
            <div
              className="circle circle__second "
              onMouseEnter={handleMouseEnterBall}
              onMouseLeave={handleMouseLeaveBall}
            >
              <div className="circle__top">
                <svg viewBox="0 0 609.1 608.9" fill="#ffaf6f">
                  <ellipse
                    cx="304.5"
                    cy="304.4"
                    rx="304.5"
                    ry="70.5"
                    fill="#ffaf6f"
                  ></ellipse>
                  <path d="M0,304.5C0,136.3,136.3,0,304.5,0S609,136.3,609,304.5"></path>
                </svg>
              </div>
              <div className="circle__bottom">
                <svg viewBox="0 0 609.1 608.9" fill="#ffaf6f">
                  <path d="M609.1,304.4c0,168.2-136.3,304.5-304.5,304.5S0,472.6,0,304.4"></path>
                  <ellipse
                    cx="304.5"
                    cy="304.4"
                    rx="304.5"
                    ry="70.5"
                    fill="#ff933b"
                  ></ellipse>
                </svg>
              </div>
            </div>
            {/* circle 3 */}
            <div
              className="circle circle__third"
              onMouseEnter={handleMouseEnterBall}
              onMouseLeave={handleMouseLeaveBall}
            >
              <div className="circle__top">
                <svg viewBox="0 0 609.1 608.9" fill="#ffaf6f">
                  <ellipse
                    cx="304.5"
                    cy="304.4"
                    rx="304.5"
                    ry="70.5"
                    fill="#ffaf6f"
                  ></ellipse>
                  <path d="M0,304.5C0,136.3,136.3,0,304.5,0S609,136.3,609,304.5"></path>
                </svg>
              </div>
              <div className="circle__bottom">
                <svg viewBox="0 0 609.1 608.9" fill="#ffaf6f">
                  <path d="M609.1,304.4c0,168.2-136.3,304.5-304.5,304.5S0,472.6,0,304.4"></path>
                  <ellipse
                    cx="304.5"
                    cy="304.4"
                    rx="304.5"
                    ry="70.5"
                    fill="#ff933b"
                  ></ellipse>
                </svg>
              </div>
            </div>
            <img
              className="others__image"
              src={moodygif2}
              ref={gifRef}
              alt="What The Case project"
            />
            <img
              className="others__image "
              src={bmo}
              ref={bmoRef}
              alt="BMO hackathon project"
            />
            <img
              className="others__image"
              src={whatthecase}
              ref={whatTheCaseRef}
              alt="What The Case project"
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default Projects;
