import React, { useEffect, useRef } from "react";
import "./Hero.scss";
import heroImageDark from "../../assets/images/portrait-dark.jpg";
import heroImageLight from "../../assets/images/portrait-light.jpg";
import gsap, { Power4 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ToggleButton from "../ToggleButton/ToggleButton";
gsap.registerPlugin(ScrollTrigger);

const Hero = ({ toggleTheme, theme }) => {
  //Create fullstack ref
  const fullStackRef = useRef(null);
  const heroRef = useRef(null);
  const scrollDownRef = useRef(null);
  const noteRef = useRef(null);
  const imageRef = useRef(null);

  const animationOnLoad = () => {
    //Get elements
    const heroEl = heroRef.current;
    const fullStackEl = fullStackRef.current;
    const scrollDownEl = scrollDownRef.current;
    const noteEl = noteRef.current;
    const imageEl = imageRef.current;

    //Animation
    const tl = gsap.timeline();
    tl
      //Starting animation after loading
      .from(heroEl, { autoAlpha: 0 })
      //Animate full stack text
      .from(fullStackEl.firstChild, {
        y: 250,
        duration: 1,
        ease: Power4.easeOut,
      })
      //Animate scroll down
      .from(
        scrollDownEl.childNodes,
        {
          y: 100,
          duration: 1,
          ease: Power4.easeOut,
        },
        "-=0.7"
      )
      //Animate note
      .from(
        noteEl.firstChild,
        {
          y: 100,
          duration: 1,
          ease: Power4.easeOut,
        },
        "<"
      )
      //Animate image
      .from(
        imageEl,
        {
          opacity: 0,
          y: 100,
          duration: 1,
        },
        "-=0.5"
      )
      // Animate all texts
      .to(".hero__animation", {
        y: -80,
        scrollTrigger: {
          trigger: heroEl,
          scrub: 4,
          start: "-53.5 top",
          end: "150% center",
        },
        ease: Power4.easeOut,
      })
      // Animate full stack text separately
      .to(
        fullStackEl,
        {
          y: -128,
          scrollTrigger: {
            trigger: heroEl,
            scrub: 4,
            start: "-53.5 top",
            end: "150% center",
          },
          ease: Power4.easeOut,
        },
        "<"
      )
      .fromTo(
        imageEl,
        { y: 0 },
        {
          y: -15,
          scale: 1.1,
          scrollTrigger: {
            trigger: heroEl,
            scrub: 4,
            start: "-53.5 top",
            end: "150% center",
          },
        },
        "<"
      );
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      animationOnLoad();
    }
  }, []);

  return (
    <header className="hero" ref={heroRef}>
      <span className="hero__title hero__title--full">Full</span>
      <div className="hero__notes">
        <span className="hero__left-note hero__animation" ref={scrollDownRef}>
          <span>Scroll Down</span>
          <ToggleButton toggleTheme={toggleTheme} theme={theme} />
        </span>
        <span className="hero__right-note hero__animation" ref={noteRef}>
          <span>I'm most productive when working with creative designs</span>
          <ToggleButton
            toggleTheme={toggleTheme}
            theme={theme}
            modifier={"tablet"}
          />
        </span>
      </div>
      <span className="hero__title hero__title--stack">Stack</span>
      <div className="hero__main-content">
        <p
          className="hero__title hero__title--full-stack hero__animation"
          ref={fullStackRef}
        >
          <span>Full Stack</span>
        </p>
        <p className="hero__title hero__title--dev hero__animation">
          Web Developer
        </p>
        <ToggleButton
          toggleTheme={toggleTheme}
          theme={theme}
          modifier={"mobile"}
        />
        <div className="hero__image-wrapper">
          <img
            src={theme === "dark" ? heroImageDark : heroImageLight}
            alt="Henry"
            className="hero__image"
            ref={imageRef}
          />
        </div>
      </div>
    </header>
  );
};

export default Hero;
